<template>
  <base-layout :title="menuItem.name" page-default-back-link="/tabs/menu" v-if="menuItem">
    <ion-grid v-if="menuItem">
             <ion-row>
                 <ion-col offset-md="2" size-md="8" offset-lg="4" size-lg="4" offset-sm="0" size-sm="12">
                    <ion-card class="ion-text-center">
    
                      <img style="width:300px" :src="'/assets/' + menuItem.image" :alt="menuItem.name" />
                      <div>
                       <i>{{menuItem.description}}</i>
                      </div>
                      <div  id="customize">
                        
                        <h2 v-if="menuItem.name != 'Granola Bar'">Customize Item</h2>
                        <ion-item v-if="flavors.length > 0">
                          <ion-label>Flavor</ion-label>
                          <ion-select v-model="flavor">
                            <ion-select-option v-for="name in flavors" :value="name" :key="name">{{name}}</ion-select-option>
                          </ion-select>
                        </ion-item>
                        <ion-list>
                          <ion-item v-for="custom in customizations" :key="custom.name">
                            {{custom.name}}<ion-toggle v-model="custom.added"></ion-toggle>
                          </ion-item>
                        </ion-list>
                        <ion-button @click='addToOrder'>Add to Cart</ion-button>
                        <ion-button @click="cancel">Cancel</ion-button>
                      </div>
                    </ion-card>
                 </ion-col>
             </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
//import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
//import { IonButton, IonThumbnail, IonImg } from '@ionic/vue';
export default  {
  name: 'MenuItemDetails',
  //components: {IonButton, IonThumbnail, IonImg},
  data() {
    return {
      id: this.$route.params.id,
      viewingAddToOrder: false,
      menuItem: null,
      flavor: null,
      flavors: [],
      customizations: [],
    };
  },
  computed: {
    // menuItem() {
    //   return this.$store.getters.menuItem(this.id);
    // }
    outOfStockItems() {
      return this.$store.getters.outOfStockItems; 
    },
    canAddToOrder() {
      return  this.$store.getters.canAddToOrder; 
    }
  },
  mounted() {
    console.log('mounted');
    this.viewingAddToOrder = this.$route.query.addtoorder == '1';
    this.menuItem = this.$store.getters.menuItem(this.id);

    // .flavors = [ 'Vanilla', 'Chocolate'];

    //this.menuItem.customizations = this.menuItem.flavors.filter(f => !this.outOfStockItems.includes(f)).map(f => ({ name: f, added: false }));
    this.customizations = this.menuItem.toppings ? this.menuItem.toppings.filter(f => !this.outOfStockItems.includes(f.name)).map(f => ({ name: f.name, added: f.defaultValue })) : [];
    this.flavors = this.menuItem.flavors ? this.menuItem.flavors.filter(f => !this.outOfStockItems.includes(f)) : [];

  },
  methods: {
    cancel() {
      this.$router.go(-1)
    },
    addToOrder() {

      // var exclusiveItems = ['Mocha', 'Vanilla', 'Hazelnut', 'Caramel', 'Chocolate', 'Blueberry', 'Apple', 'Oranges', 'Bananas', 'Strawberry', 'Mango', 'Peach', 'Piña Colada'];

      // if (this.customizations.filter(f => f.added).filter(c => exclusiveItems.includes(c.name)).length != 1 && this.menuItem.name != "Hot Chocolate" && this.menuItem.name != "Granola Bar" && this.menuItem.name != "Fruit")
      // {
      //   alert("please choose one flavor.");
      // }else if (this.menuItem.name == "Fruit" && this.menuItem.customizations.filter(f => f.added).filter(c => exclusiveItems.includes(c.name)).length != 1)
      // {
      //   alert("Please choose one type of fruit.")
      // }else 
      // {
        if ((this.flavor != null && this.flavor != '')  || this.flavors.length == 0)
        {
          var menuItem = {...this.menuItem}; //clone object (so we don't have to reset after)
          menuItem.customizations = this.customizations;
          if (this.flavor) {
            menuItem.customizations.push({name: this.flavor, added: true});  //add selected flavor
          }

          this.$store.dispatch('addOrderReviewItem', menuItem);
          this.viewingAddToOrder = false;
          //this.menuItem = null;
          //this.menuItem = this.$store.getters.menuItem(this.id);
          //this.menuItem.customizations = this.flavors.map(f => ({ name: f, added: false }));
          this.$router.go(-1)
        } else {
          alert('Please choose a flavor');
        }
      //}
      
    },
  }
};
</script>